export const pathConstants = {
  MAIN: "/",
  ABOUT: "/about",
  RESUME: "/resume",
  TRAVEL: "/travel",
  SULSULSIMSIM: "/sulsulsimsim",
  HANDYMAP: "/handymap",
  JUSTSHOOT: "/justshoot",
  RDS: "/rds",
  COVIDFORECASTS: "/covidforecasts",
  ULTRAHACKS: "/bouncerbot",
  PINKEFFECT: "/pinkeffect",
  HUZTLE: "/huztle",
  CS3113: "/cs3113",
  CS4553: "/cs4553",
  DM2133: "/dm2133",
  GIRLSWHOCODE: "/gwc",
  STARHACKS: "/starhacks",
  EXPLORECSR: "/exploreCSR",
};
